import AppRoutes from "./AppRoutes";

function App() {
  return (
   <AppRoutes/>
  );
}
//74277427Aq!

export default App;
